import React from 'react';
import { Button, Container, Row, Col, Card, ProgressBar, Form, Alert } from 'react-bootstrap';
import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import { JobType } from './jobType';
import EntityCard from './entityCard';
import Highlighter from "react-highlight-words";


interface IObjectKeys {
  [key: string]: number;
}

interface HighlightMarker {
  start: number;
  end: number;
  type: string;
}

type jobPostJson = {
  config: {
    language: string,
  },
  fields:
    {
      language: string,
      name: string,
      occurrence: number,
      record_id: string,
      text: string
    }[]
  ,
  institution_id: string
}


const App = () => {
  const [entities, setEntities] = React.useState<JobType>()
  const [labels, setLabels] = React.useState<IObjectKeys>()
  const [inputText, setInputText] = React.useState<string>("")
  const [state, setState] = React.useState<string>("start")
  const [textAreaModel, setTextAreaModel] = React.useState("")
  const [progressValue, setProgressValue] = React.useState(0)
  const [HLwords, setHLwords] = React.useState<string[]>([])
  const [textCount, setTextCount] = React.useState(0)
  const [html, setHtml] = React.useState<string>("")
  const [loggedin, setLoggedin] = React.useState(0)
  let isHighlighted = false;
  let progVal = 0;
  let recordText = "";

  // Set base URL
  let BASEURL = "https://test.axiell.io/api/entity-extraction-service/latest";
  /*if (process.env.REACT_APP_URLBASE === undefined) {
    throw new Error("Environment variable REACT_APP_URLBASE is undefined");
  }*/
  if (process.env.REACT_APP_URLBASE !== undefined) {
    BASEURL = process.env.REACT_APP_URLBASE;
  }


  const SampleTexts = [
    "The geometric layout of Washington, D.C.'s streets and green spaces, originally designed by Pierre L'Enfant, reserved a prominent space for a monument to George Washington at the intersection of lines radiating south from the White House and west of the Capitol. In 1833, the Washington National Monument Society, a private organization, formed to fund and build a monument to the first president that would be ”unparalleled in the world.” The Society solicited for donations and designs for a decade, settling on a design by Robert Mills in 1845. Mills' design called for a 600-foot Egyptian-style obelisk ringed by thirty 100-foot columns. The design was audacious, ambitious, and expensive, creating numerous complications during its construction. Despite difficulties raising funds, construction began on the Washington Monument in 1848. The cornerstone was laid on July 4 with upwards of 20,000 people in attendance including President James K. Polk; former First Lady Dolley Madison; Eliza Hamilton, widow of Alexander Hamilton, Washington's Treasury Secretary; George Washington Parke Custis; and future presidents Buchanan, Lincoln, and Johnson. Builders commenced work on the blue gneiss foundation, an 80-foot square step pyramid. With the substructure completed, the builders then proceeded to the above-ground marble structure, 55 feet, 1.5 inches square at the base, using a system of pulleys, block and tackle systems, and a mounted derrick to hoist and place the stones, inching the structure skyward. By 1854, the monument had reached a height of 156 feet above ground, but a turn of events stalled construction.",
    "Mona Lisa's famously enigmatic smile has fascinated viewers for centuries. Among her first admirers was King François I, who invited Leonardo da Vinci to France and bought the painting from him in 1518. This is how the world's most famous painting entered the royal collections that have been shown at the Louvre since the French Revolution. Since 2005, the Mona Lisa has been exhibited in a protective glass case, in solitary splendour in the centre of the room. This special treatment stems partly from the need to ensure the safety of such a famous work, but is also due to conservation requirements: the work was not painted on canvas, but on a panel of poplar wood which has warped over the years, causing a crack to appear. To prevent further damage, the Mona Lisa has to be kept in a temperature and humidity-controlled glass case.",
    "Guernica, a large black-and-white oil painting executed by Spanish artist Pablo Picasso in 1937 following the German bombing of Guernica, a city in Spain's Basque region. The complex painting received mixed reviews when it was shown in the Spanish Republic Pavilion at the world’s fair in Paris, but it became an icon as it traveled the world in ensuing years, raising controversies on its meaning and its rightful home. Picasso was living in Paris when the Spanish Republican government approached him in 1937 with a commission to produce a mural for their pavilion in that year’s world’s fair. Spain was six months into its civil war—a military revolt undertaken by the Nationalists against the government—and the Republicans saw the international event as an opportunity to assert its legitimacy and to condemn the brutal tactics of Gen. Francisco Franco’s Nationalist army. Picasso, who rarely mixed politics and art, accepted. Several months later, German aircraft, at the request of the Nationalists, heavily bombed the city of Guernica on April 26. The three-hour long blitzkrieg nearly annihilated the city and killed or wounded one-third of the population. Coverage of the devastation set Picasso to work on the commission, and he completed the enormous painting (11.5 x 25.5 feet [3.49 x 7.77 metres]) in about three weeks."
  ]

  const URLBASE = BASEURL;


  let labelList: IObjectKeys = {}
  let highlights: HighlightMarker[] = []

  const translateLabel = (label: string) => {
    let newLabel = ""
    labelTranslations.forEach(function (translation) {
      if (translation.name === label) {
        newLabel = translation.label;
      }
    })
    return newLabel
  }

  const labelTranslations = [
    { name: "PERSON", label: "Person" },
    { name: "NORP", label: "Nationalities, religious or political group" },
    { name: "FAC", label: "Building, structure" },
    { name: "ORG", label: "Organization" },
    { name: "GPE", label: "Geographic location" },
    { name: "LOC", label: "Location" },
    { name: "PRODUCT", label: "Product" },
    { name: "EVENT", label: "Event" },
    { name: "WORK_OF_ART", label: "Work of art" },
    { name: "LAW", label: "Law" },
    { name: "LANGUAGE", label: "Language" },
    { name: "DATE", label: "Date" },
    { name: "TIME", label: "Time" },
    { name: "PERCENT", label: "Percent" },
    { name: "MONEY", label: "Money" },
    { name: "QUANTITY", label: "Quantity" },
    { name: "ORDINAL", label: "Ordinal date" },
    { name: "CARDINAL", label: "Cardinal date" }

    /*PERSON
    NORP
    FAC
    ORG
    GPE
    LOC
    PRODUCT
    EVENT
    WORK_OF_ART
    LAW
    LANGUAGE
    DATE
    TIME
    PERCENT
    MONEY
    QUANTITY
    ORDINAL
    CARDINAL*/
  ]


  const HighlightText = (input: String, markers: HighlightMarker[]) => {
    let text = input;

    markers.sort((left, right) => { return left.start < right.start ? 1 : -1 })
    markers.forEach((marker) => {
      text = text.substring(0, marker.start) + "<em class='" + marker.type + "'>" + input.substring(marker.start, marker.end) + "</em>" + text.substring(marker.end);
    })

    isHighlighted = true;

    console.log("Output:", text);
    setHtml(text as string);


  }


  // Check job status
  const FetchSatus = (id: string) => {
    if (progVal > 60) progVal += 1
    else progVal += 5
    setProgressValue(progVal)

    const URL = URLBASE + "/jobs/" + id + "/entities"
    const requestOptions = {
      method: 'GET',
      //mode: 'no-cors' as RequestMode,
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': '4138f581-f89a-486d-9c0f-a37a1c48dc45',
      }
    };
    fetch(URL, requestOptions)
      .then(response => response.json())
      .then((data) => {
        if (data.job_status !== "COMPLETED") {
          // TODO: Check if entities are added, and set the list, even if we don't have links yet.
          if (data.entities && data.entities.length > 0) {
            FetchEntities(id, false);
            setState("linking")
          }
          setTimeout(() => {
            FetchSatus(id);
          }, 1500)
        } else {
          setProgressValue(0);
          FetchEntities(id, true);
        }

      })
  }

  // Fetch generated entities
  const FetchEntities = (id: string, done: boolean) => {
    let hlwords: string[] = [];
    const URL = URLBASE + "/jobs/" + id + "/entities"
    const requestOptions = {
      method: 'GET',
      //mode: 'no-cors' as RequestMode,
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': '4138f581-f89a-486d-9c0f-a37a1c48dc45',
      }
    };
    fetch(URL, requestOptions)
      .then(response => response.json())
      .then((data) => {
        // Normalize score, between -12.0 -> 12.9
        /*for (let i = 0; i < data.entities.length; i++) {
          let s = data.entities[i].score;
          data.entities[i].score_norm = Math.round((s+12.9)/25.8 * 100);
        }*/
        setEntities(data)
        if (done) {
          setState("done")
          done = true;
        }

        if (data && data.entities) {
          for (let i = 0; i < data.entities.length; i++) {

            // Set lables
            const label = data.entities[i].mention.label;
            if (label in labelList) {
              labelList[label] = labelList[label] + 1;
            } else {
              labelList[label] = 1;
            }

            // Set highlights
            highlights.push({ start: data.entities[i].mention.start, end: data.entities[i].mention.end, type: data.entities[i].mention.label })
            // Set highlight words
            //hlwords.push(data.entities[i].entity_text)
          }
        }
        setLabels(labelList);
        if (!isHighlighted)
          HighlightText(recordText, highlights);
        setHLwords(hlwords);
      })
  }


  // POST request to create a job
  const PostJob = (data: jobPostJson) => {
    setState("loading")

    const URL = URLBASE + "/jobs/extraction_and_linking"
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': '4138f581-f89a-486d-9c0f-a37a1c48dc45',
        'accept': 'application/json'
      },
      body: JSON.stringify(data)
    };

    fetch(URL, requestOptions)
      .then(response => response.json())
      .then((data) => {
        FetchSatus(data._id)
      })

    //AnimateWords();
  }



  // TODO: Se till att input lagras i ett state så att det kan visas som en text-box istället för en input-box!

  // Handle submit on the text form
  const SubmitRecord = (event: React.FormEvent) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    recordText = Object.fromEntries(formData.entries()).recordText as string
    if (recordText === "") return;

    setInputText(recordText);

    const postData = {
      "config": {
        "language": "en"
      },
      "fields": [
        {
          "language": "en",
          "name": "description",
          "occurrence": 1,
          "record_id": "record_123",
          "text": recordText
        }
      ],
      "institution_id": "axiell-test"
    }

    PostJob(postData);
  }

  const SubmitRecordWithoutForm = (inputText: string) => {
    if (inputText === "") return
    recordText = inputText;

    setInputText(recordText);

    const postData = {
      "config": {
        "language": "en"
      },
      "fields": [
        {
          "language": "en",
          "name": "description",
          "occurrence": 1,
          "record_id": "record_123",
          "text": recordText
        }
      ],
      "institution_id": "axiell-test"
    }
    PostJob(postData);
  }

  const resetApp = (e: React.UIEvent) => {
    e.preventDefault()

    setState("start")
    setTextAreaModel("")
    setTextCount(0)
    setHLwords([])
    setProgressValue(0)
    setEntities({ entities: [] } as JobType)
    setHtml("")
  }

  const setSampleText = (i: number) => {

    setTextAreaModel(SampleTexts[i])
    SubmitRecordWithoutForm(SampleTexts[i])
  }

  const handleSignOn = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    let recordText = Object.fromEntries(formData.entries()).password as string
    if(recordText === "XQJFPTHZ") 
      setLoggedin(2)
    else 
      setLoggedin(1)
  }



  return (
    <div className="App">


      <Container fluid="sm">
        <Row>
          <Col className="header-row">
            <h2><img src="https://www.axiell.com/app/themes/axiell/dist/images/logo_b3660c53.svg" alt="Axiell Logo"></img> Intelligence</h2>

          </Col>
          <Col className="header-link">
            {state === "done" && <a href="#new" onClick={resetApp}>+ New analysis</a>}
          </Col>
        </Row>
        {/*<Row className="record-row">
            <Col xs="auto">
            <Alert variant='info' key='info'>Axiell Intelligence is currently unavailable due to maintenance. We'll be back soon!...</Alert>
            </Col>
          </Row>*/}
        {(loggedin < 2) &&
        <Form onSubmit={handleSignOn}>
          <Row className="record-row">
            <Col xs="auto">
              <Form.Control type="password" name="password"></Form.Control>
              {(loggedin === 1) &&
              <p>Wrong password</p>
              }
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit">Sign in</Button>
            </Col>
          </Row>
          </Form>
        }

        {(loggedin === 2) &&
          <Row className="record-row">
            <Col>

              <form method="post" onSubmit={SubmitRecord}>


                <Card className="card-layout card-input">
                  {state === "start" &&
                    <textarea placeholder='Enter text here...' name="recordText" value={textAreaModel} onChange={(e) => { setTextAreaModel(e.target.value); setTextCount(e.target.value.length) }} rows={15} maxLength={2000}></textarea>
                  }
                  {state === "start" &&
                    <p className="text-count">{textCount} / 2000</p>
                  }
                  {state !== "start" && <span>
                    {!html &&
                      <Highlighter className='input-text' textToHighlight={inputText} searchWords={HLwords} highlightClassName="highlighted-word" autoEscape={true} />
                    }
                    {html && <p dangerouslySetInnerHTML={{ __html: html }}></p>}

                  </span>
                  }



                  <div className="toolbar">
                    <Row className="align-items-centerx">
                      <Col className="progress-info">

                        {state === "start" &&
                          <Button variant="primary" type="submit" disabled={state !== "start" || textAreaModel === ""} className="btn-analyse">Extract entities</Button>
                        }
                        {state === "loading" &&
                          <span>Extracting entities</span>
                        }
                        {state === "linking" &&
                          <span>Linking entities</span>
                        }
                        {(state === "loading" || state === "linking") &&
                          <ProgressBar now={progressValue} animated variant="info"></ProgressBar>
                        }
                      </Col>
                    </Row>
                  </div>




                </Card>


              </form>
            </Col>

            {state !== "start" &&
              <Col className="right">

                <Card className="card-layout card-tags">
                  <h5>Entity categories</h5>

                  {(entities && entities.entities!.length === 0) &&
                    <p className="sub-text">Tags will appear here.</p>
                  }
                  {entities && entities.entities!.length > 0 && <span>
                    {labels && Object.keys(labels).map((key, i) => (
                      <span className={'entity-label ' + key} key={i} title={translateLabel(key)}> {key}{/*Number of entites with label: labels[key]*/}</span>
                    ))}
                  </span>
                  }
                </Card>

                <Card className="card-layout card-entities">
                  <h5>Entities {entities && entities.entities && entities.entities!.length > 0 && <span># {entities.entities!.length}</span>}</h5>
                  {/*state !== "done" &&
                <p className="sub-text">Tags will appear here.</p>
            */}
                  {entities && entities.entities!.length > 0 &&
                    entities.entities!.map((item, i) => (

                      <EntityCard data={item} key={i} />

                    ))
                  }
                </Card>
              </Col>
            }
            {(state === "start") &&
              <Col>
                <div className="intro-text">
                  <h2>Elevate your Axiell Collections data using AI</h2>
                  <h3>- a sneak peak of what is to come</h3>
                  <p>What this demo shows is an example of how you can take unstructured data and turn it into structured data. Eventually, this will be an embedded feature in Axiell Collections and will allow you to do much more with the resources that you have.</p>
                  <p>This AI service automatically identifies and highlights key entities in any text, such as names, locations, dates, and other critical data. It extracts them instantly, enhancing your data and improving searchability with ease.</p>
                  <p>Here's how you use this demo application:</p>
                  <ol>
                    <li>Type, paste or use one of the sample texts provided into the input box on the left side.</li>
                    <li>Click the “Extract entities” button to begin. Now you should see the extracted entities beginning to appear. You can already check out the entities while the service is completing.</li>
                    <li>Once finished, you can see the identified entities in the text and their links to Wikidata in the entities list to the right. The higher the confidence score, the stronger the indication that the Wiki link is correct.</li>
                  </ol>
                  <p>Please note that this demo version works best in English. In future versions, we will extend the service to support more entities and languages. </p>
                  <p><a href="#sample1" onClick={(e) => { e.preventDefault(); setSampleText(0) }}>Sample text 1 (Washinton monument)</a></p>
                  <p><a href="#sample2" onClick={(e) => { e.preventDefault(); setSampleText(1) }}>Sample text 2 (Mona Lisa)</a></p>
                  <p><a href="#sample3" onClick={(e) => { e.preventDefault(); setSampleText(2) }}>Sample text 3 (Guernica)</a></p>
                </div>
              </Col>
            }
          </Row>
        }

      </Container>
    </div>
  );
}

export default App;
